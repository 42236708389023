import React from "react";
import ArticleBackground from "../../background.js";
import ArticleBlock from "../../block.js";
import ArticleImage from "../../image.js";
import Icon from "Components/Layout/Icon/index.js";

class ArticleBlockExpandables extends ArticleBlock
{
    constructor(props)
    {
        super(props);
        this.Fields =
        {
            expandablesNotice:
            {
                label: "All sections are expanded while in editing mode.",
                type: "notice"
            },
            title:
            {
                default: "Title",
                demo: "Lorem ipsum dolor",
                label: "Title",
                type: "textarea"   
            },
            items:
            {
                addLabel: "Add section",
                demo: [
                    {
                        content: "<p>Nullam maximus, lacus eget tristique faucibus, tortor ante tincidunt tellus, vitae condimentum urna turpis lacinia ipsum. Integer ornare, eros ac faucibus pharetra, mauris magna hendrerit ipsum, ut viverra risus orci sit amet ante. Donec mattis, massa in porta euismod, ex sem imperdiet nunc, vitae convallis ipsum augue vitae nulla.</p>",
                        ctaLabel: "Read More",
                        ctaLink: "https://fuseuniversal.com/",
                        id: "demoaccordion1",
                        label: "Accordion #1"
                    },
                    {
                        content: "<p>Pellentesque placerat, risus vel mattis fringilla, lorem lacus feugiat lorem, sed dapibus lorem ligula vitae quam. In vitae risus a diam ornare pulvinar bibendum at leo. Aenean sodales rhoncus nunc. Nulla in consequat tellus, vitae sollicitudin libero. Sed fringilla nec velit ac tincidunt. Mauris dictum, est scelerisque molestie lobortis, lacus magna malesuada dui, ac hendrerit nibh diam in felis.</p>",
                        ctaLabel: "Read More",
                        ctaLink: "https://fuseuniversal.com/",
                        id: "demoaccordion2",
                        label: "Accordion #2"
                    },
                    {
                        content: "<p>Vivamus condimentum, felis et venenatis dapibus, odio urna pellentesque sem, eu cursus purus ex at velit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer finibus ultricies dolor, eget tempus velit. Curabitur vitae gravida ante, at ornare velit. Cras sit amet felis diam. Nunc nec justo augue. Sed at metus dapibus, consequat neque at, suscipit elit.</p>",
                        ctaLabel: "Read More",
                        ctaLink: "https://fuseuniversal.com/",
                        id: "demoaccordion3",
                        label: "Accordion #3"
                    }
                ],
                fields:
                {
                    label:
                    {
                        label: "Label"
                    },
                    content:
                    {
                        label: "Content",
                        type: "wysiwyg"   
                    },
                    image:
                    {
                        label: "Image",
                        type: "image"
                    },
                    ctaLabel:
                    {
                        label: "Call to action - Label",
                        placeholder: "Read more"
                    },
                    ctaLink:
                    {
                        label: "Call to action - Link",
                        placeholder: "https://"
                    },
                    ctaAlign:
                    {
                        default: "center",
                        label: "Call to action - Alignment",
                        options: {
                            left: "Left",
                            center: "Center",
                            right: "Right"
                        },
                        type: "select"
                    }
                },
                itemName: "Section",
                label: "Sections",
                type: "repeater"
            },
            alignTitle:
            {
                default: "center",
                label: "Title alignment",
                options: {
                    left: "Left",
                    center: "Center",
                    right: "Right"
                },
                type: "select"
            },
            backgroundImage:
            {
                label: "Background image",
                type: "image"
            },
            backgroundOverlay:
            {
                default: false,
                displayIf: ["backgroundImage", "!==", "empty"],
                label: "Background overlay",
                type: "checkbox"
            }
        };
    }

    Item = ({ctaAlign, ctaLabel, ctaLink, content: html, image, label}, index) =>
    {
        const {disabled, editable, images, onImageUrl} = this.props;
        return (
            <div className="ex-sn" key={index} data-expand={editable ? "1" : "0"}>
                <div className="ex-sn-w">
                    <div className="ex-sn-t">
                        {this.EditableText(label, "div", ["items", index, "label"], "ex-sn-l")}
                        <Icon className="ex-sn-c" feather="ChevronDown"/>
                    </div>
                    <div className="ex-sn-cl">
                        <div className="ex-sn-cw">
                            {(image && image.length) ? (editable ? (
                                <div className="ViewArticlesImageWrapper">
                                    <ArticleImage
                                        className="ex-sn-i"
                                        disabled={disabled}
                                        editable={editable}
                                        id={["items", index, "image"]}
                                        imageId={image}
                                        onEdit={this.OnEditableChange}
                                        onUrl={onImageUrl}
                                        srcref={images}
                                    />
                                </div>
                            ) : (
                                <ArticleImage
                                    className="ex-sn-i"
                                    editable={editable}
                                    imageId={image}
                                    onUrl={onImageUrl}
                                    srcref={images}
                                />
                            )) : ""}
                            {this.EditableContent(html, ["items", index, "content"], "ex-sn-cn", true)}
                            {this.CallToAction(ctaLabel, ctaLink, ctaAlign, ["items", index, "ctaLabel"])}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render()
    {
        const {
            attributes,
            content,
            disabled,
            editable,
            id,
            images,
            onImageUrl,
            style
        } = this.props;
        const {
            alignTitle = "center",
            backgroundImage,
            backgroundOverlay,
            items = [],
            title
        } = content || {};
        const CA = ["cb", `cb-${id}`, "ex"];
        if (items.length > 0)
        {
            CA.push("_hi");
        }
        if (editable)
        {
            CA.push("_ce");
        }
        switch (alignTitle)
        {
            case "left":
                CA.push("_al");
                break;
            case "right":
                CA.push("_ar");
                break;
            default:
                CA.push("_ac");
        }
        return (
            <>
                {this.Appearance()}
                <ArticleBackground
                    attributes={attributes}
                    className={CA.join(" ")}
                    disabled={disabled}
                    editable={editable}
                    id="backgroundImage"
                    imageId={backgroundImage}
                    onEdit={this.OnEditableChange}
                    onUrl={onImageUrl}
                    overlay={backgroundOverlay}
                    srcref={images}
                    style={style}
                >
                    {title ? (
                        <div className="ex-tp">
                            {this.EditableText(title, "h2", "title", "ex-t")}
                        </div>
                    ) : ""}
                    {items.length ? (
                        <div className="ex-sns">
                            {items.map((item, index) => this.Item(item, index))}
                        </div>
                    ) : ""}
                </ArticleBackground>
            </>
        );
    }
}

export default ArticleBlockExpandables;