/**!
 *  Widget grid field.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./widgetsfield.scss";
import Globals from "Class/Globals";
import {ArrayClone, ObjectCompare, RandomToken, ObjectPrint} from "Functions";
import Grid from "Components/UI/Grid";
import Preview from "Components/Layout/Preview";
import IconItem from "Components/UI/IconItem";

class WidgetsField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Listeners = [];
        this.Templates = {};
        this.state =
        {
            rowHeights: [],
            selected: "",
            widgets: []
       };
   }

    /**
     * Parse value on mount.
     * @return void
     */

    componentDidMount()
    {
        const {value} = this.props;
        this.SetValue(value);
   }

    /**
     * Re-parse widgets when a new object is received.
     * @return void
     */

    componentDidUpdate()
    {
        const {value} = this.props;
        const {rowHeights: r2, widgets: w2} = this.state;
        let RowHeights, Widgets;
        if (Array.isArray(value))
        {
            RowHeights = [];
            Widgets = this.ParseWidgets(value);
        }
        else if (typeof value === "object")
        {
            const {rowHeights: r1, widgets: w1} = value;
            RowHeights = r1 || [];
            Widgets = this.ParseWidgets(w1);
        }
        if (!ObjectCompare(RowHeights, r2) || !ObjectCompare(Widgets, w2))
        {
            this.SetValue({rowHeights: RowHeights, widgets: Widgets});
        }
    }

    /**
     * Remove listeners on unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Listeners.forEach(id =>
        {
            Globals.Remove(`var-template-${id}`, this.OnTemplate);
        });
    }

    /**
     * Add a listener for when a sub-widget changes template.
     * @param string id - The unique id of the widget.
     * @return void
     */

    AddListener = (id) =>
    {
        if (this.Listeners.indexOf(id) >= 0)
        {
            return;
        }
        Globals.Listen(`var-template-${id}`, this.OnTemplate);
        const Template = Globals.Var(`template-${id}`);
        this.Listeners.push(id);
        this.Templates[id] = Template ? Template[1] : "";
    }

    /**
     * Add a new widget.
     * @param object e - The event object.
     * @return void
     */

    AddWidget = (e) =>
    {
        e.stopPropagation();
        const {defaultHeight, defaultWidth, id, onChange} = this.props;
        const {rowHeights, widgets} = this.state;
        const Id = RandomToken();
        const Widgets = ArrayClone(widgets);
        Widgets.push({
            id: Id,
            width: defaultWidth,
            height: defaultHeight
        });
        this.setState({selected: Id, widgets: Widgets});
        onChange(e, {rowHeights: ArrayClone(rowHeights), widgets: this.SortWidgets(Widgets)}, id);
    }

    /**
     * Delete the currently selected widget.
     * @return void
     */

    DeleteWidget = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
        Globals.DialogCreate({
            confirmLabel: "Delete Widget",
            message: "Are you sure that you want to delete the selected widget?",
            onConfirm: () =>
            {
                const {id, onChange} = this.props;
                const {rowHeights, selected, widgets} = this.state;
                let Index = -1;
                widgets.forEach((widget, index) =>
                {
                    if (Index >= 0 || widget.id !== selected)
                    {
                        return;
                    }
                    Index = index;
                });
                if (Index < 0)
                {
                    return;
                }
                this.RemoveListener(id);
                const Widgets = ArrayClone(widgets);
                Widgets.splice(Index, 1);
                this.setState({selected: "", widgets: Widgets});
                onChange(e, {rowHeights: ArrayClone(rowHeights), widgets: this.SortWidgets(Widgets)}, id);
            },
            title: "Delete Widget",
            type: "confirm"
        });
    }

    /**
     * Switch to the currently selected widget.
     * @return void
     */

    EditWidget = () =>
    {
        const {selected} = this.state;
        Globals.ViewActive(selected);
    }

    /**
     * @param object e - The event object.
     * @param object items - The altered grid items.
     * @return void
     */
    
    OnChange = (e, items) =>
    {
        const {id, onChange} = this.props;
        const {rowHeights, widgets} = this.state;
        const Widgets = ArrayClone(widgets);
        const Dimensions = {};
        items.forEach(dimensions =>
        {
            Dimensions[dimensions.id] = dimensions;
        });
        Widgets.forEach(widget =>
        {
            const {width, height, x, y} = Dimensions[widget.id] || {};
            widget.width = width;
            widget.height = height;
            widget.x = x;
            widget.y = y;
        });
        this.setState({widgets: Widgets});
        onChange(e, {rowHeights: ArrayClone(rowHeights), widgets: this.SortWidgets(Widgets)}, id);
    }

    /**
     * Switch active view when an item is double clicked.
     * @param object e - The event object.
     * @param string id - The unique id of the widget.
     * @return void
     */

    OnDoubleClick = (e, id) =>
    {
        const {disabled} = this.props;
        if (disabled)
        {
            return;
        }
        Globals.ViewActive(id);
    }
    
    /**
     * Callback when the cursor enters a widget.
     * @param object e - The event object.
     * @param string id - The unique id of the widget.
     * @return void
     */
    
    OnMouseEnter = (e, id) =>
    {
        const {disabled} = this.props;
        if (disabled)
        {
            return;
        }
        Globals.ViewHover(id, true);
    }

    /**
     * Callback when the cursor leaves a widget.
     * @param object e - The event object.
     * @param string id - The unique id of the widget.
     * @return void
     */
    
    OnMouseLeave = (e, id) =>
    {
        const {disabled} = this.props;
        if (disabled)
        {
            return;
        }
        Globals.ViewHover(id, false);
    }

    OnRowHeights = (e, rowHeights) =>
    {
        const {onChange, id} = this.props;
        const {widgets} = this.state;
        this.setState({rowHeights});
        onChange(e, {rowHeights: ArrayClone(rowHeights), widgets: this.SortWidgets(widgets)}, id);
    }

    /**
     * Callback when a widget is selected in the grid.
     * @param object e - The event object.
     * @param string selected - The unique id of the selected widget.
     * @return void
     */
    
    OnSelect = (e, selected) =>
    {
        const {id, onSelect} = this.props;
        this.setState({selected});
        onSelect(e, selected, id);
    }

    /**
     * Callback when a widgets template changes.
     * @param string [id] - The unique id of the widget.
     * @param string [template] - The widget template key.
     * @return void
     */
    
    OnTemplate = ([id, template]) =>
    {
        this.Templates[id] = template;
        this.forceUpdate();
    }

    ParseWidgets = (widgets) =>
    {
        const {defaultHeight, defaultWidth} = this.props;
        widgets.forEach(widget =>
        {
            const {id, width, height, x, y} = widget;
            if (!id)
            {
                widget.id = RandomToken();
            }
            widget.width = parseInt(width, 10) || defaultWidth;
            widget.height = parseInt(height, 10) || defaultHeight;
            widget.x = parseInt(x, 10) || 0;
            widget.y = parseInt(y, 10) || 0;
        });
    }

    /**
     * Remove event listeners for a widget.
     * @param string id - The unique id of the widget.
     * @return void
     */
    
    RemoveListener = (id) =>
    {
        const Index = this.Listeners.indexOf(id);
        if (Index < 0)
        {
            return;
        }
        Globals.Remove(`var-template-${id}`, this.OnTemplate);
        this.Listeners.splice(Index, 1);
    }

    SetRowHeights = (rowHeights) =>
    {
        this.setState({rowHeights: rowHeights || []});
    }

    SetValue = (value) =>
    {
        if (Array.isArray(value))
        {
            this.SetWidgets(value);
        }
        else if (typeof value === "object")
        {
            const {rowHeights, widgets} = value;
            this.SetWidgets(widgets, rowHeights);
        }
    }

    /**
     * Parse widget dimensions into integers.
     * @param object widgets - The unparsed widgets.
     * @param array rowHeights - Optional. Set row heights.
     * @return void
     */
    
    SetWidgets = (widgets, rowHeights = false) =>
    {
        if (!widgets)
        {
            return;
        }
        widgets.forEach(widget =>
        {
            this.AddListener(widget.id);
        });
        if (rowHeights)
        {
            this.setState({rowHeights: rowHeights || [], widgets})
        }
        else
        {
            this.setState({widgets})
        };
    }

    /**
     * Sort widgets according to their order in the grid, from top left to bottom right.
     * @param object widgets - The unparsed widgets.
     * @param boolean clone - Whether to clone the widgets array before parsing it.
     * @return array - The parsed widgets array.
     */

    SortWidgets = (widgets, clone = true) =>
    {
        const {columns} = this.props;
        const Widgets = clone ? ArrayClone(widgets) : widgets;
        Widgets.sort((a, b) =>
        {
            const o1 = a.y * columns + a.x;
            const o2 = b.y * columns + b.x;
            if (o1 < o2) return -1;
            if (o1 > o2) return 1;
            return 0;
        });
        return Widgets;
    }

    /**
     * Get the field value.
     * @return string - The field value.
     */

    Value = () =>
    {
        const {rowHeights, widgets} = this.state;
        return {
            rowHeights: ArrayClone(rowHeights),
            widgets: ArrayClone(widgets)
        };
    }

    render()
    {
        const {className, columns, defaultRow, disabled, label, rowHeightInterval, rowHeightMin} = this.props;
        const {rowHeights, selected, widgets} = this.state;
        const CA = ["WidgetsField"];
        const Items = [];
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (className)
        {
            CA.push(className);
        }
        widgets.forEach(widget =>
        {
            const {id, width, height, x, y} = widget;
            const Item = {id, width, height, x, y};
            const Template = this.Templates[id] || "Blank";
            Item.content = (
                <div className="WidgetsFieldWidget">
                    <Preview
                        className="WidgetsFieldIcon"
                        content={`widget-${Template}`}
                    />
                </div>
            );
            Items.push(Item);
        });
        return (
            <div className={CA.join(" ")}>
                {label ? <label>{label}</label> : ""}
                <Grid
                    className="WidgetsFieldGrid"
                    columns={columns}
                    disabled={disabled}
                    emptyText="No widgets have been added"
                    items={Items}
                    minSize={60}
                    onChanged={this.OnChange}
                    onDoubleClick={this.OnDoubleClick}
                    onMouseEnter={this.OnMouseEnter}
                    onMouseLeave={this.OnMouseLeave}
                    onRowHeights={this.OnRowHeights}
                    onSelect={this.OnSelect}
                    rowHeight={defaultRow}
                    rowHeightInterval={rowHeightInterval}
                    rowHeightMin={rowHeightMin}
                    rowHeights={rowHeights}
                    selected={selected}
                />
                <div className="WidgetsFieldTray">
                    <IconItem
                        className="WidgetsFieldTrayItem"
                        disabled={disabled}
                        feather="Plus"
                        label="Add widget"
                        onClick={this.AddWidget}
                    />
                    <IconItem
                        className="WidgetsFieldTrayItem"
                        disabled={disabled || !selected}
                        feather="Edit2"
                        label="Edit selected"
                        onClick={this.EditWidget}
                    />
                    <IconItem
                        className="WidgetsFieldTrayItem"
                        disabled={disabled || !selected}
                        feather="Trash"
                        label="Delete selected"
                        onClick={this.DeleteWidget}
                    />
                </div>
            </div>
       );
   }
}

WidgetsField.propTypes =
{
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    rowHeightInterval: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

WidgetsField.defaultProps =
{
    className: "",
    columns: 6,
    defaultHeight: 1,
    defaultWidth: 2,
    defaultRow: 2,
    disabled: false,
    id: "",
    label: "",
    onChange: () => {},
    onSelect: () => {},
    rowHeightInterval: 0.01,
    rowHeightMin: 0.1,
    value: []
};

export default WidgetsField;