import React from "react";
import {ArrayClone, Ln2Br} from "Functions";
import Wysiwyg from "Components/UI/Wysiwyg";

class ArticleBlock extends React.Component
{
    componentDidMount()
    {
        const {id, onMount} = this.props;
        onMount(this, id);
    }

    componentWillUnmount()
    {
        const {id, onUnmount} = this.props;
        onUnmount(this, id);
    }

    Appearance = () =>
    {
        const {appearance, getAppearance, id} = this.props;
        return getAppearance(appearance, id);
    }

    CallToAction = (label, link, align, id, className) =>
    {
        if (!label)
        {
            return "";
        }
        const CA = ["cb-i", "cb-bt"];
        switch (align)
        {
            case "left":
                CA.push("_al");
                break;
            case "right":
                CA.push("_ar");
                break;
            default:
                CA.push("_ac");
        }
        if (className)
        {
            CA.push(className);
        }
        return (
            <div className="cb-bt-ct">
                {this.EditableLink(label, link, id, CA.join(" "))}
            </div>
        )
    }

    EditableContent = (html, id, className, flipDropdowns = false, allowMedia = true) =>
    {
        const {disabled, editable} = this.props;
        return editable === true ? (
            <Wysiwyg
                allowMedia={allowMedia}
                className={className}
                disabled={disabled}
                flipDropdowns={flipDropdowns}
                id={id}
                minimal={true}
                onChange={this.OnEditableChange}
                value={html}
            />
        ) : React.createElement("div", {
                className,
                dangerouslySetInnerHTML: {__html: html}
        });
    }

    EditableLink = (label, link, id, className) =>
    {
        const {editable} = this.props;
        if (editable === true)
        {
            return this.EditableText(label, "span", id, className);
        }
        else
        {
            return <a className={className} href={link} target="_blank" rel="noopener noreferrer">{label}</a>;
        }
    }

    EditableText = (text, nodeType, id, className, params = {}) =>
    {
        const {disabled, editable} = this.props;
        return editable === true ? React.createElement(nodeType, {
            ...params,
            className,
            children: (
                <Wysiwyg
                    disabled={disabled}
                    id={id}
                    minimal={2}
                    onChange={this.OnEditableChange}
                    value={text}
                />
            )}) : React.createElement(nodeType, {
                ...params,
                className,
                children: Ln2Br(text)
            });
    }

    GetContentFields = () =>
    {
        return this.Fields || {};
    }

    GetDemoContent = () =>
    {
        const Fields = this.GetContentFields();
        const DemoAppearance = Object.assign({}, this.DemoAppearance || {});
        const DemoContent = {};
        for (let key in Fields)
        {
            const {default: defaultValue, demo} = Fields[key];
            const DemoValue = demo === undefined ? defaultValue : demo;
            if (DemoValue === undefined)
            {
                continue;
            }
            else if (typeof DemoValue === "object")
            {
                DemoContent[key] = ArrayClone(DemoValue);
            }
            else
            {
                DemoContent[key] = DemoValue;
            }
        }
        return [DemoAppearance, DemoContent];
    }

    OnEditableChange = (e, value, id) =>
    {
        const {id: blockId, onField} = this.props;
        onField(e, id, value, blockId);
    }

    render()
    {
        return "";
    }
}

ArticleBlock.defaultProps =
{
    appearance: {},
    attributes: {},
    content: {},
    disabled: false,
    editable: true,
    getAppearance: () => {},
    getReadTime: () => {},
    id: "",
    images: {},
    onField: () => {},
    onImageUrl: () => {},
    onMount: () => {},
    onUnmount: () => {},
    style: {}
};

export default ArticleBlock;