import React from "react";
import ArticleBackground from "../../background.js";
import ArticleBlock from "../../block.js";
import ArticleImage from "../../image.js";

class ArticleBlockText extends ArticleBlock
{
    constructor(props)
    {
        super(props);
        this.Fields =
        {
            content:
            {
                default: "<h2>Title</h2><p>Lorem ipsum dolor sit amet.</p>",
                demo: "<h2>Lorem ipsum dolor sit amet</h2><p>Nullam maximus, lacus eget tristique faucibus, tortor ante tincidunt tellus, vitae condimentum urna turpis lacinia ipsum. Integer ornare, eros ac faucibus pharetra, mauris magna hendrerit ipsum, ut viverra risus orci sit amet ante. Donec mattis, massa in porta euismod, ex sem imperdiet nunc, vitae convallis ipsum augue vitae nulla.</p>",
                label: "Content",
                type: "wysiwyg"   
            },
            image:
            {
                label: "Image",
                type: "image"
            },
            ctaLabel:
            {
                demo: "Read more",
                label: "Call to Action - Label",
                placeholder: "Read more"
            },
            ctaLink:
            {
                demo: "https://fuseuniversal.com/",
                label: "Call to Action - Link",
                placeholder: "https://"
            },
            ctaAlign:
            {
                default: "center",
                label: "Call to Action - Alignment",
                options: {
                    left: "Left",
                    center: "Center",
                    right: "Right"
                },
                type: "select"
            },
            backgroundImage:
            {
                label: "Background Image",
                type: "image"
            },
            backgroundOverlay:
            {
                default: false,
                displayIf: ["backgroundImage", "!==", "empty"],
                label: "Background Overlay",
                type: "checkbox"
            }
        };
    }

    render()
    {
        const {
            attributes, 
            content,
            disabled,
            editable,
            id,
            images,
            onImageUrl,
            style
        } = this.props;
        const {
            backgroundImage,
            backgroundOverlay,
            content: html,
            ctaAlign,
            ctaLabel,
            ctaLink,
            image
        } = content || {};
        const CA = ["cb", `cb-${id}`, "tx"];
        if (editable)
        {
            CA.push("_ce");
        }
        return (
            <>
                {this.Appearance()}
                <ArticleBackground
                    attributes={attributes}
                    className={CA.join(" ")}
                    disabled={disabled}
                    editable={editable}
                    id="backgroundImage"
                    imageId={backgroundImage}
                    onEdit={this.OnEditableChange}
                    onUrl={onImageUrl}
                    overlay={backgroundOverlay}
                    srcref={images}
                    style={style}
                >
                    <div className="tx-cn">
                        {(image && image.length) ? (editable ? (
                            <div className="ViewArticlesImageWrapper">
                                <ArticleImage
                                    className="tx-i"
                                    disabled={disabled}
                                    editable={editable}
                                    id="image"
                                    imageId={image}
                                    onEdit={this.OnEditableChange}
                                    onUrl={onImageUrl}
                                    srcref={images}
                                />
                            </div>
                        ) : (
                            <ArticleImage
                                className="tx-i"
                                disabled={disabled}
                                editable={editable}
                                id="image"
                                imageId={image}
                                onEdit={this.OnEditableChange}
                                onUrl={onImageUrl}
                                srcref={images}
                            />
                        )) : ""}
                        {this.EditableContent(html, "content", "tx-ht")}
                        {this.CallToAction(ctaLabel, ctaLink, ctaAlign, "ctaLabel")}
                    </div>
                </ArticleBackground>
            </>
        );
    }
}

export default ArticleBlockText;