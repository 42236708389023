/*!
 *  Image details interface.
 *
 *  @prop string className - Append a class name.
 *  @prop object image - Image object.
 *  @prop function onClose - CAllback when the close button is clicked.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./imagedetails.scss";
import API from "Class/API";
import {DateTimeStamp, ObjectCompare} from "Functions";
import Button from "Components/UI/Button";
import ContentField from "Components/UI/Field/ContentField";
import IconItem from "Components/UI/IconItem";
import LoadImage from "Components/Layout/LoadImage";
import Spinner from "Components/Feedback/Spinner";
import TextField from "Components/UI/Field/TextField";
import TextareaField from "Components/UI/Field/TextareaField";

class ImageDetails extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Initial = {};
        this.Mounted = false;
        this.state = {
            communities: [],
            description: "",
            error: false,
            filename: "",
            id: 0,
            loading: false,
            ratio: 9 / 16,
            regenerating: false,
            saving: false,
            src: "",
            token: "",
            users: []
        };
    }

    /**
     *  Load preview on mount.
     *  @return void.
     */

    componentDidMount()
    {
        this.Mounted = true;
        this.SetImage(this.props.image);
    }

    /**
     *  Reset image object if the prop changes.
     *  @return void.
     */

    componentDidUpdate(prevProps)
    {
        const {image: i1} = this.props;
        const {image: i2} = prevProps;
        if (!ObjectCompare(i1, i2))
        {
            this.SetImage(i1);
        }
    }

    /**
     *  Register unmount.
     *  @return void.
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }


    /**
     *  Check whether the image information has been edited.
     *  @return bool - Whether the image information has been edited.
     */

    IsEdited = () =>
    {
        const {
            communities: c1,
            description: d1,
            filename: f1,
            users: u1
        } = this.Initial;
        const {
            communities: c2,
            description: d2,
            filename: f2,
            users: u2 
        } = this.state;
        return (d1 !== d2 || f1 !== f2 || !ObjectCompare(c1, c2) || !ObjectCompare(u1, u2));
    }

    /**
     *  Callback when a field has been edited.
     *  @param object e - Event object.
     *  @param mixed value - The new value.
     *  @param string id - Field id.
     *  @return void.
     */

    OnEdit = (e, value, id) =>
    {
        const State = {};
        State[id] = value;
        this.setState(State);
    }

    /**
     *  Callback when the preview image loads.
     *  @param string src - Preview src url.
     *  @param int width - Width in pixels.
     *  @param int height - Height in pixels;
     *  @return void.
     */

    OnLoad = (src, width, height) =>
    {
        this.setState({
            loading: false,
            ratio: Math.min(height / width, 1)
        });
    }

    OnRegenerate = (e, token) =>
    {
        const {onRegenerate} = this.props;
        this.setState({regenerating: true});
        onRegenerate(e, token, () =>
        {
            if (!this.Mounted)
            {
                return;
            }
            this.setState({regenerating: false});
        });
    }

    /**
     *  Callback when the save button is clicked.
     *  @return void.
     */

    OnSave = () =>
    {
        const {onSave} = this.props;
        const {
            communities,
            description,
            filename,
            id,
            token,
            users
        } = this.state;
        this.setState({
            error: false,
            saving: true
        });
        API.Request("files/update", {
            communities,
            description,
            filename,
            id,
            users
        }, response =>
        {
            const {error, message} = response;
            if (error)
            {
                this.setState({
                    error: message || true,
                    saving: false
                });
            }
            else
            {
                this.Initial = {
                    communities,
                    description,
                    filename,
                    users
                };
                this.setState({saving: false});
                onSave(null, this.Initial, token);
            }
        });
    }

    /**
     *  Extract info from an image object.
     *  @param object image - The image object.
     *  @return void.
     */

    SetImage = (image) =>
    {
        const {
            communities,
            created,
            description,
            filename,
            id,
            token,
            urls,
            users
        } = image || {};
        const {src} = this.state;
        const {mid} = urls || {};
        const Created = DateTimeStamp(new Date(created));
        this.Initial = {
            communities: communities || [],
            description: description || "",
            filename: filename || "",
            users: users || []
        };
        this.setState( {
            communities: communities || [],
            created: Created,
            description: description || "",
            filename: filename || "",
            id,
            loading: src !== mid,
            src: mid,
            token,
            users: users || []
        });
    }

    render()
    {
        const {className, onClose, onDelete} = this.props;
        const {
            communities,
            description,
            filename,
            id,
            loading,
            ratio,
            regenerating,
            saving,
            src,
            token,
            users
        } = this.state;
        const CA = ["ImageDetails"];
        const Disabled = !id || saving || !token;
        const Edited = this.IsEdited();
        const Loading = loading || !src;
        if (Loading)
        {
            CA.push("Loading");
        }
        if (className)
        {
            CA.push(className);
        }
        const Ratio = ratio * 100 + "%";
        return (
            <div className={CA.join(" ")}>
                <div
                    className="ImageDetailsPreview"
                    style={{padding: `0 0 ${Ratio}`}}
                >
                    {src ? <LoadImage
                    
                        className="ImageDetailsPreviewImage"
                        onLoad={this.OnLoad}
                        src={src}
                    /> : ""}
                    {Loading ? <Spinner overlay={true}/> : ""}
                </div>
                <div className="ImageDetailsForm">
                    <p className="ImageDetailsId">ID: {id}</p>
                    <TextField
                        disabled={Disabled}
                        id="filename"
                        label="Filename"
                        onChange={this.OnEdit}
                        onInput={this.OnEdit}
                        value={filename}
                    />
                    <TextareaField
                        disabled={Disabled}
                        id="description"
                        label="Description"
                        onChange={this.OnEdit}
                        onInput={this.OnEdit}
                        value={description}
                    />
                    <ContentField
                        disabled={Disabled}
                        id="communities"
                        label="Communities"
                        onChange={this.OnEdit}
                        multiple={true}
                        placeholder="Search for community..."
                        types={["community"]}
                        value={communities || []}
                    />
                    <ContentField
                        disabled={Disabled}
                        id="users"
                        label="Uploaded by"
                        onChange={this.OnEdit}
                        multiple={true}
                        placeholder="Search for user..."
                        types={["user"]}
                        value={users || []}
                    />
                </div>
                <div className="ImageDetailsTray">
                    <Button
                        disabled={!Edited}
                        label="Save changes"
                        loading={saving}
                        onClick={this.OnSave}
                    />
                    <Button
                        hollow={true}
                        label="Close"
                        onClick={onClose}
                    />
                </div>
                <div className="ImageDetailsTraySecond">
                    <IconItem
                        disabled={Disabled}
                        feather="Trash"
                        id={token}
                        label="Delete"
                        onClick={onDelete}
                        title="Permanently delete this image file from the gallery"
                    />
                    {0 ? <IconItem
                        disabled={Disabled}
                        feather="RefreshCw"
                        id={token}
                        label="Regenerate"
                        loading={regenerating}
                        onClick={this.OnRegenerate}
                        title="Regenerate each size of this image"
                    /> : ""}
                </div>
            </div>
        );
    }
}

ImageDetails.propTypes =
{
    className: PropTypes.string,
    image: PropTypes.object,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onSave: PropTypes.func
};

ImageDetails.defaultProps =
{
    className: "",
    image: {},
    onClose: () => {},
    onDelete: () => {},
    onRegenerate: () => {},
    onSave: () => {}
};

export default ImageDetails;