import React from "react";
import ArticleBackground from "../../background.js";
import ArticleBlock from "../../block.js";

class ArticleBlockTop extends ArticleBlock
{
    constructor(props)
    {
        super(props);
        this.DemoAppearance =
        {
            colorBackground: "#000000",
            colorText: "#ffffff",
            inheritAppearance: false,
        };
        this.Fields =
        {
            title:
            {
                default: "Title",
                demo: "Large Title",
                label: "Title",
                type: "textarea"   
            },
            intro:
            {
                default: "Intro",
                demo: "Praesent tellus tortor, finibus vitae massa non, cursus facilisis velit.",
                label: "Intro",
                type: "textarea"   
            },
            readTimeShow:
            {
                default: true,
                demo: true,
                label: "Show est. read time",
                type: "checkbox"   
            },
            ctaLabel:
            {
                demo: "Read more",
                label: "Call to action - Label",
                placeholder: "Read more"
            },
            ctaLink:
            {
                demo: "https://fuseuniversal.com/",
                label: "Call to action - Link",
                placeholder: "https://"
            },
            alignContent:
            {
                default: "center",
                demo: "center",
                label: "Content alignment",
                options: {
                    left: "Left",
                    center: "Center",
                    right: "Right"
                },
                type: "select"
            },
            backgroundImage:
            {
                demo: ["34"],
                label: "Background image",
                type: "image"
            },
            backgroundOverlay:
            {
                default: false,
                demo: true,
                displayIf: ["backgroundImage", "!==", "empty"],
                label: "Background overlay",
                type: "checkbox"
            },
            fitImage:
            {
                default: false,
                demo: false,
                displayIf: ["backgroundImage", "!==", "empty"],
                label: "Fit image",
                type: "checkbox"
            }
        };
    }

    render()
    {
        const {
            attributes, 
            content,
            disabled,
            editable,
            getReadTime,
            id,
            images,
            onImageUrl,
            style
        } = this.props;
        const {
            alignContent = "center",
            backgroundImage,
            backgroundOverlay,
            ctaLabel,
            ctaLink,
            fitImage,
            intro,
            readTimeShow,
            title
        } = content || {};
        const CA = ["cb", `cb-${id}`, "tp"];
        if (editable)
        {
            CA.push("_ce");
        }
        switch (alignContent)
        {
            case "left":
                CA.push("_al");
                break;
            case "right":
                CA.push("_ar");
                break;
            default:
                CA.push("_ac");
        }
        return (
            <>
                {this.Appearance()}
                <ArticleBackground
                    after={readTimeShow ? (
                        <div className="cb-i tp-rt">
                            {getReadTime() || 0} min read
                        </div>
                    ) : ""}
                    attributes={attributes}
                    className={CA.join(" ")}
                    disabled={disabled}
                    editable={editable}
                    fitBackground={fitImage}
                    id="backgroundImage"
                    imageId={backgroundImage}
                    onEdit={this.OnEditableChange}
                    onUrl={onImageUrl}
                    overlay={backgroundOverlay}
                    srcref={images}
                    style={style}
                >
                    <div className="tp-cn">
                        {this.EditableText(title, "h1", "title", "tp-t")}
                        {this.EditableText(intro, "div", "intro", "tp-in")}
                        {this.CallToAction(ctaLabel, ctaLink, alignContent, "ctaLabel")}
                    </div>
                </ArticleBackground>
            </>
        );
    }
}

export default ArticleBlockTop;