import React from "react";
import ArticleBackground from "../../background.js";
import ArticleBlock from "../../block.js";
import ArticleImage from "../../image.js";
import Icon from "Components/Layout/Icon";
class ArticleBlockGallery extends ArticleBlock
{
    constructor(props)
    {
        super(props);
        this.Fields =
        {
            title:
            {
                default: "Title",
                demo: "Lorem ipsum dolor",
                label: "Title",
                type: "textarea"   
            },
            items:
            {
                addLabel: "Add slide",
                demo: [
                    {
                        content: "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Mauris sed fringilla est.",
                        ctaLabel: "Read More",
                        ctaLink: "https://fuseuniversal.com/",
                        id: "demoslide1",
                        image: ["35"],
                        name: "Slide #1",
                    },
                    {
                        content: "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Mauris sed fringilla est.",
                        ctaLabel: "Read More",
                        ctaLink: "https://fuseuniversal.com/",
                        id: "demoslide2",
                        image: ["34"],
                        name: "Slide #2",
                    }
                ],
                fields:
                {
                    name:
                    {
                        displayIf: ["1", "!==", "1"],
                        label: "Name"
                    },
                    content:
                    {
                        label: "Content",
                        type: "wysiwyg"   
                    },
                    image:
                    {
                        label: "Image",
                        type: "image"
                    },
                    ctaLabel:
                    {
                        label: "Call to action - Label",
                        placeholder: "Read more"
                    },
                    ctaLink:
                    {
                        label: "Call to action - Link",
                        placeholder: "https://"
                    },
                    ctaAlign:
                    {
                        default: "center",
                        label: "Call to action - Alignment",
                        options: {
                            left: "Left",
                            center: "Center",
                            right: "Right"
                        },
                        type: "select"
                    }
                },
                itemName: "Slide",
                label: "Slides",
                maxItems: 20,
                onOpen: this.OnEditSlide,
                type: "repeater"
            },
            alignTitle:
            {
                default: "center",
                label: "Title alignment",
                options: {
                    left: "Left",
                    center: "Center",
                    right: "Right"
                },
                type: "select"
            },
            autoplay:
            {
                label: "Autoplay",
                type: "checkbox",
                default: true
            },
            interval:
            {
                displayIf: ["autoplay", "==", true],
                label: "Switch interval (seconds)",
                minValue: 0,
                type: "number",
                default: 7
            },
            animationsNotice:
            {
                displayIf: ["autoplay", "==", true],
                label: "The carousel doesn't play animations while in editing mode.",
                type: "notice"
            },
            fixedHeight:
            {
                default: true,
                label: "Fixed image height",
                type: "checkbox"
            },
            fixedValue:
            {
                default: "67%",
                displayIf: ["fixedHeight", "===", true],
                label: "Set height",
            },
            showArrows:
            {
                default: true,
                label: "Show navigation arrows",
                type: "checkbox"
            },
            showDots:
            {
                default: true,
                label: "Show navigation dots",
                type: "checkbox"
            },
            backgroundImage:
            {
                label: "Background image",
                type: "image"
            },
            backgroundOverlay:
            {
                default: false,
                displayIf: ["backgroundImage", "!==", "empty"],
                label: "Background overlay",
                type: "checkbox"
            }
        };
        this.state =
        {
            active: 0,
            numItems: 0
        };
    }

    componentDidMount()
    {
        const {id, content, onMount} = this.props;
        const {items = []} = content;
        this.setState({active: 0, numItems: items.length}, () => onMount(this, id));
    }

    componentDidUpdate()
    {
        // Switch to new slide.
        const {active, numItems} = this.state;
        const {content} = this.props;
        const {items = []} = content;
        if (items.length > numItems || active >= items.length)
        {
            this.setState({active: items.length - 1, numItems: items.length});
        }
    }

    Item = ({ctaAlign, ctaLabel, ctaLink, content: html, image}, index, numItems) =>
    {
        const {content, disabled, editable, images, onImageUrl} = this.props;
        const {active} = this.state;
        const {fixedHeight, fixedValue} = content;
        return (
            <div className="gl-sl" data-active={index === active ? "1" : "0"} key={index} style={{left: index / numItems * -100 + "%"}}>
                <div className="gl-sl-cw">
                    {(image && image.length) ? <div className="gl-sl-iw" style={fixedHeight ? {padding: `0 0 ${fixedValue}`} : {}}>
                        <ArticleImage
                            className="gl-sl-i"
                            disabled={disabled}
                            editable={editable}
                            id={["items", index, "image"]}
                            imageId={image}
                            onEdit={this.OnEditableChange}
                            onUrl={onImageUrl}
                            srcref={images}
                        />
                    </div> : ""}
                    <div className="gl-sl-cn">
                        {this.EditableContent(html, ["items", index, "content"], "gl-sl-cn", true)}
                        {this.CallToAction(ctaLabel, ctaLink, ctaAlign, ["items", index, "ctaLabel"])}
                    </div>
                </div>
            </div>
        );
    }

    OnArrow = (e) =>
    {
        const {active, numItems} = this.state;
        const Set = active + parseInt(e.currentTarget.dataset.step, 10);
        this.setState({active: Set < 0 ? numItems + Set : Set % numItems});
    }

    OnEditSlide = (e, index) =>
    {
        this.setState({active: index});
    }

    OnDot = (e) =>
    {
        this.setState({active: parseInt(e.currentTarget.dataset.index, 10)});
    }

    render()
    {
        const {
            attributes, 
            content, 
            disabled,
            editable,
            id,
            images,
            onImageUrl,
            style
        } = this.props;
        const {active} = this.state;
        const {
            alignTitle = "center",
            autoplay,
            backgroundImage,
            backgroundOverlay,
            fixedHeight,
            fixedValue,
            interval,
            items = [],
            showArrows = true,
            showDots = true,
            title
        } = content || {};
        const CA = ["cb", `cb-${id}`, "gl"];
        const ShowNavigation = items.length > 1;
        if (editable)
        {
            CA.push("_ce");
        }
        switch (alignTitle)
        {
            case "left":
                CA.push("_al");
                break;
            case "right":
                CA.push("_ar");
                break;
            default:
                CA.push("_ac");
        }
        if (fixedHeight)
        {
            CA.push("_fh");
        }
        else
        {
            CA.push("_ah");
        }
        if (showArrows && ShowNavigation)
        {
            CA.push("_ha");
        }
        if (showDots && ShowNavigation)
        {
            CA.push("_hd");
        }
        if (items.length > 0)
        {
            CA.push("_hi");
        }
        return (
            <>
                {this.Appearance()}
                <ArticleBackground
                    attributes={attributes}
                    className={CA.join(" ")}
                    disabled={disabled}
                    editable={editable}
                    id="backgroundImage"
                    imageId={backgroundImage}
                    onEdit={this.OnEditableChange}
                    onUrl={onImageUrl}
                    overlay={backgroundOverlay}
                    srcref={images}
                    style={style}
                >
                    {title ? (
                        <div className="gl-tp">
                            {this.EditableText(title, "h2", "title", "gl-t")}
                        </div>
                    ) : ""}
                    <div className="gl-nv">
                        <div className="gl-sls" data-interval={autoplay ? interval : 0}>
                            <div className="gl-sls-w" style={{width: items.length * 100 + "%"}}>
                                {items.map((item, index) => this.Item(item, index, items.length))}
                            </div>
                        </div>
                        {(ShowNavigation && showArrows) ? (
                            <div className="gl-nv-as" style={fixedHeight ? {padding: `0 0 ${fixedValue}`} : {}}>
                                <div className="cb-i cb-bt-r gl-nv-a _nl" data-step="-1" onClick={this.OnArrow}><Icon feather="ArrowLeft"/></div>
                                <div className="cb-i cb-bt-r gl-nv-a _nr" data-step="1" onClick={this.OnArrow}><Icon feather="ArrowRight"/></div>
                            </div>
                        ) : ""}
                    </div>
                    {(ShowNavigation && showDots) ? (
                        <div className="gl-nv-ds">
                            {items.map((item, index) => <div className="gl-nv-d" data-active={index === active ? "1" : "0"} data-index={index} key={index} onClick={this.OnDot}><div className="gl-nv-df cb-i"/></div>)}
                        </div>
                    ) : ""}
                </ArticleBackground>
            </>
        );
    }
}

export default ArticleBlockGallery;